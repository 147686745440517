export const dataType = {
  AMOUNT: "CURRENCY",
  DATE: "DATE",
  HYPERLINK: "HYPERLINK",
  TEXT: "ALPHANUMERIC",
  LONG_TEXT: "ALPHANUMERIC",
  NUMBER: "ALPHANUMERIC",
};

export const createCustomFieldStructure = (
  customInvoiceFields,
  literalAddedData,
  isEnabledData
) => {
  const compareInvoiceAllData = isEnabledData
    ? isEnabledData.map((item) => item.cid)
    : [];
  const comparedDataCustomFields = customInvoiceFields
    ? customInvoiceFields.filter((item) =>
        compareInvoiceAllData.includes(item.path)
      )
    : [];

  return comparedDataCustomFields.map((field, idx) => {
    const { path, name, data_type } = field;
    const literalId = literalAddedData[path] || name;
    return {
      accessor: `custom_field.${path}`,
      alignment: "left",
      cell_type: dataType[data_type] || "ALPHANUMERIC",
      col_id: `col_cf_${idx}`,
      filter_by: false,
      header_alignment: "left",
      is_hidden: false,
      literal_id: literalId,
      order: 0,
      sort_by: false,
      type: "STRING",
      width: 150,
    };
  });
};
