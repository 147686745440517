import React, { useContext, useEffect, useState, useMemo } from "react";

import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  Row,
} from "@tanstack/react-table";
import { Input } from "baseui/input";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedNumber, useIntl } from "react-intl";
import moment from "moment";

import { Icon, Typography } from "../../components_v2";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  getGridStructure,
  customerInvoiceOverview,
  putInvoiceEdit,
  deleteCharges,
  invoiceDownload,
  putBusinessStatus,
  getOrganization,
} from "../../services";
import StatusType from "../../components/TableCell/StatusType/StatusType";
import { KIND, TextButton } from "../../components/TextButton";
import { IconButton, SIZE } from "../../components/IconButton";
import { Label1, ParagraphMedium, ParagraphSmall } from "baseui/typography";
import DownArrow from "../../utils/DownArrow";
import RecurringSvg from "../../assets/img/svg/RecurringSvg";
import CurrencyFormate from "../../components_v2/CurrencyFormate";
import { CO_INVOICES_, CUSTOMER_OVERVIEW_ } from "../../constants";
import columnMapper_V2 from "../../utils/mapper_v2";
import CustomFieldsTab from "../../components/CustomFields/CustomFieldsTab";
import TypesOfInvoice from "../../components/TypesOfInvoice/TypesOfInvoice";
import { useDebouncedCallback } from "use-debounce";
import {
  ADJUSTMENT_DRAWER,
  setDrawerState,
  subscriptionsDrawer,
} from "../../redux/actions";
import { useDispatch } from "react-redux";
import InvLevelCustomFieldsTab from "./CustomFields/InvLevelCustomFieldsTab";
import { Controller, useForm } from "react-hook-form";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { TextBox } from "../../components/TextBox";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../components/TextArea";
import { Modal } from "baseui/modal";
import { toast } from "react-toastify";
import {
  ADD_ADJUSTMENT,
  ASSIGN_BUSSINESS_LOCATION,
  DELETE_INVOICE,
  EDIT_CHARGES,
  EDIT_INVOICE,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import queryClient from "../../providers/queryClient";
import _ from "lodash";
import { ModalContext } from "../../providers/ModalProvider";
import warningImage from "../../../src/assets/img/warning.png";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import MultiFilters from "../../components/MultiFilters/MultiFilters";
import useFormat from "../../hooks/useFormat";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";
import BusinessStatus from "../../components/BusinessStatus/BusinessStatus";
import InvLevelitemizationFields from "./CustomFields/InvLevelitemizationFields";
import { createCustomFieldStructure } from "../../components/CreateGridStructure";

const InvoiceBody = ({
  row,
  deleteChargesInv,
  setInvoiceEditModal,
  setInvoiceEditData,
  renderSubComponent,
}) => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  let dispatch = useDispatch();

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const { currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const { handleModal } = useContext(ModalContext);

  const [isLoading, setIsLoading] = useState(false);

  const [businessStatus, setBusinessStatus] = useState(false);
  const [businessData, setBusinessData] = useState({});

  return (
    <>
      <div key={row.id} className="co-inv-table_body-group --modifi_edit">
        {row.getVisibleCells().map((cell, inx) => {
          return (
            <div
              key={cell.id}
              style={{
                ...(inx === 0
                  ? { maxWidth: cell?.column?.columnDef?.width }
                  : {}),
              }}
              className="co-table_body-cell"
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          );
        })}
        <>
          <StatefulPopover
            content={({ close }) => (
              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "3px",
                  borderRadius: "5px",
                }}
              >
                {((currentDefaultFormatDetails &&
                  currentDefaultFormatDetails?.accounting_book === "CSV" &&
                  !row?.original?.is_system_generated) ||
                  (currentDefaultFormatDetails.accounting_book === "ROTESSA" &&
                    !row?.original?.is_system_generated)) && (
                  <RBACWrapper role={EDIT_INVOICE} type="PERMISSION">
                    <div
                      className="co_action_inv"
                      onClick={() => {
                        setInvoiceEditModal(true);
                        setInvoiceEditData(row && row.original);
                        close();
                      }}
                    >
                      <Icon icon="edit" size={16} />
                      <Typography type="p">Edit</Typography>
                    </div>
                    {/* <div
                      className="co_action_inv"
                      onClick={() => {
                        setBusinessStatus(true);
                        setBusinessData({
                          businessUnit:
                            row && row.original && row.original.business_unit,
                          businessLocation:
                            row &&
                            row.original &&
                            row.original.business_location,
                          customerId: "",
                          invoiceId: "",
                        });
                      }}
                    >
                      <Typography type="p">Client</Typography>
                    </div> */}
                  </RBACWrapper>
                )}
                {row && row.original && row.original.is_system_generated && (
                  <RBACWrapper
                    // role={EDIT_RECURRING_INVOICE}
                    role={
                      row && row.original && row.original.type === "CHARGE"
                        ? EDIT_CHARGES
                        : EDIT_INVOICE
                    }
                    type="PERMISSION"
                  >
                    <div
                      className="co_action_inv"
                      onClick={() => {
                        navigate(
                          `/customers?customer=${customerId}&invoice=invoice-form&inv=${
                            row && row.original && row.original._id
                          }&type=${
                            row &&
                            row.original &&
                            row.original.type === "CHARGE"
                              ? "EDIT-CHARGE"
                              : "EDIT-INV"
                          }`
                        );
                      }}
                    >
                      <Icon icon="edit" size={16} />
                      <Typography type="p">Edit</Typography>
                    </div>
                  </RBACWrapper>
                )}

                <RBACWrapper role={ASSIGN_BUSSINESS_LOCATION} type="PERMISSION">
                  <div
                    className="co_action_inv"
                    onClick={() => {
                      setBusinessStatus(true);
                      setBusinessData({
                        businessUnit:
                          row && row.original && row.original.business_unit,
                        businessLocation:
                          row && row.original && row.original.business_location,
                        customerId:
                          row && row.original && row.original.customer_id,
                        invoiceId: "",
                      });
                    }}
                  >
                    <Icon icon="business_unit" size={16} />
                    <Typography type="p">Client</Typography>
                  </div>
                </RBACWrapper>

                {currentDefaultFormatDetails &&
                  currentDefaultFormatDetails?.accounting_book === "CSV" &&
                  row?.original?.is_system_generated &&
                  row?.original?.type === "CHARGE" && (
                    <RBACWrapper role={DELETE_INVOICE} type="PERMISSION">
                      <div
                        className="co_action_inv"
                        onClick={() => {
                          handleModal({
                            // title: "",
                            content:
                              "This will Delete the Invoice Permanently, Would you like to Continue",
                            successCallback: () => {
                              deleteChargesInv.mutate({
                                invoiceId: row?.original?._id,
                              });
                            },
                            formContent: ({ handleModal }) => {
                              return <></>;
                            },
                            cancelCallback: () => {},
                            isChildren: false,
                            image: warningImage,
                            buttonText: "Delete",
                            closeButtonText: "Cancel",
                            isCloseAble: false,
                          });
                        }}
                      >
                        <Icon icon="delete" size={16} />
                        <Typography type="p">Delete</Typography>
                      </div>
                    </RBACWrapper>
                  )}

                {((currentDefaultFormatDetails.accounting_book === "CSV" &&
                  row.original?.is_system_generated) ||
                  (currentDefaultFormatDetails.accounting_book === "CSV" &&
                    row.original?.invoive_file_doc_id) ||
                  (currentDefaultFormatDetails?.accounting_book !== "CSV" &&
                    currentDefaultFormatDetails?.accounting_book !==
                      "ROTESSA")) && (
                  <div
                    className="co_action_inv"
                    onClick={async () => {
                      if (!isLoading) {
                        await setIsLoading(true);
                        invoiceDownload({
                          invoiceId: row.original._id,
                          organization: currentOrganization,
                        }).then(() => setIsLoading(false));
                      }
                    }}
                  >
                    <Icon icon="download" size={16} />
                    <Typography type="p">Download</Typography>
                  </div>
                )}
                <RBACWrapper role={ADD_ADJUSTMENT} type="PERMISSION">
                  <div
                    className="co_action_inv"
                    onClick={() => {
                      setDrawer(ADJUSTMENT_DRAWER, { invID: row.original._id });
                      close();
                    }}
                  >
                    <Icon icon="adjustment" size={18} />
                    <Typography type="p">Adjustment</Typography>
                  </div>
                </RBACWrapper>
              </div>
            )}
            triggerType={TRIGGER_TYPE.hover}
            returnFocus
            autoFocus
          >
            {((row && row.original && row.original.is_system_generated) ||
              currentDefaultFormatDetails.accounting_book == "CSV" ||
              currentDefaultFormatDetails?.accounting_book !== "CSV" ||
              currentDefaultFormatDetails?.accounting_book !== "ROTESSA" ||
              (row?.original?.is_system_generated &&
                row?.original?.type === "CHARGE")) && (
              <div className="co-inv-table-edit">
                <Icon icon="triple_dot" isPressable size="18" />
              </div>
            )}
          </StatefulPopover>
        </>
      </div>
      <div>
        {row.getIsExpanded() && (
          <div>
            {/* 2nd row is a custom 1 cell row */}
            <div colSpan={row.getVisibleCells().length}>
              {renderSubComponent({ row })}
            </div>
          </div>
        )}
      </div>
      <BusinessStatus
        businessStatus={businessStatus}
        setBusinessStatus={setBusinessStatus}
        service={putBusinessStatus}
        currentOrganization={currentOrganization}
        getParams={customerId}
        businessData={businessData}
        // businessUnitData={customerDetails?.business_unit}
        // businessUnitLocation={customerDetails?.business_location}
      />
    </>
  );
};

const InvoiceEditModal = ({
  invoiceEditModal,
  setInvoiceEditModal,
  invoiceEditData,
  setInvoiceEditData,
}) => {
  const [originalAmountChanged, setOriginalAmountChanged] = useState(false);
  const [invoiceAmountChanged, setInvoiceAmountChanged] = useState(false);
  let dispatch = useDispatch();
  let intl = useIntl();

  let { currentOrganization, currentDefaultFormatDetails, referenceData } =
    useContext(MaxyfiContext);

  let findBu =
    referenceData && referenceData["business_unit_list"]
      ? referenceData["business_unit_list"]?.find(
          (e) => e.id == invoiceEditData?.business_unit
        )
      : {};

  const { customerId } = useParams();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_cancelled: false,
    },
  });

  let {
    not_paid_amount,
    invoice_total_amount,
    invoice_due_date,
    invoice_date,
  } = watch();

  useEffect(() => {
    if (
      currentDefaultFormatDetails &&
      !currentDefaultFormatDetails.is_compute_due_amount &&
      not_paid_amount > invoice_total_amount
    ) {
      setError("not_paid_amount", {
        type: "custom",
        message: "Due Amount is Less than Invoice Amount.",
      });
    } else {
      clearErrors("not_paid_amount");
    }
  }, [not_paid_amount, invoice_total_amount]);

  useEffect(() => {
    let invDate = moment(invoice_date).startOf("day").utc().valueOf();
    let invDueDate = moment(invoice_due_date).startOf("day").utc().valueOf();

    if (invDate > invDueDate) {
      setError("invoice_due_date", {
        type: "custom",
        message: "Due Date is Less Than Invoice Date",
      });
    } else {
      clearErrors("invoice_due_date");
    }
  }, [invoice_date, invoice_due_date]);

  const editInvoiceData = useMutation(
    (data) =>
      putInvoiceEdit({
        organization: currentOrganization,
        invoiceId: invoiceEditData?._id,
        // letterId: letterId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;

          let errorData = data.error;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        setInvoiceEditModal(false);
        queryClient.refetchQueries([`${CO_INVOICES_}${customerId}`]);
        queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
      },
    }
  );

  useEffect(() => {
    if (invoiceEditData) {
      setValue(
        "invoice_number",
        invoiceEditData && invoiceEditData?.invoice_number
      );
      setValue("original_amount", invoiceEditData && invoiceEditData?.orgiamt);
      setValue(
        "invoice_date",
        invoiceEditData && invoiceEditData?.invoice_date
      );
      setValue(
        "invoice_total_amount",
        invoiceEditData && invoiceEditData?.invoice_total_amount?.value
      );
      setValue(
        "not_paid_amount",
        invoiceEditData &&
          invoiceEditData.not_paid_amount &&
          invoiceEditData.not_paid_amount.value > 0
          ? Number(invoiceEditData.not_paid_amount.value)
          : 0
      );
      setValue(
        "invoice_due_date",
        invoiceEditData && invoiceEditData?.invoice_due_date
      );
      setValue(
        "client_reference",
        _.get(invoiceEditData, "client_reference", "")
      );
      setValue("comments", _.get(invoiceEditData, "comment", ""));
      if (invoiceEditData && invoiceEditData.status === "cancelled") {
        setValue("is_cancelled", true);
      } else {
        setValue("is_cancelled", false);
      }
    }
  }, [invoiceEditData]);

  const onSubmit = async (data) => {
    let values = {
      invoice_number: data.invoice_number,
      client_reference: data.client_reference,
      invoice_date: moment(data.invoice_date).startOf("day").utc().valueOf(),
      invoice_due_date: moment(data.invoice_due_date)
        .startOf("day")
        .utc()
        .valueOf(),
      ...(data.original_amount
        ? {
            orgiamt: Number(data.original_amount),
          }
        : {}),

      invoice_total_amount: {
        value: Number(data.invoice_total_amount),
        currency: invoiceEditData?.invoice_total_amount?.currency,
      },
      ...(!currentDefaultFormatDetails.is_compute_due_amount
        ? {
            not_paid_amount: {
              value: Number(data.not_paid_amount),
              currency: invoiceEditData?.not_paid_amount?.currency,
            },
          }
        : {}),

      is_cancelled: data.is_cancelled ? data.is_cancelled : false,
      user_comments: data.comments,
    };
    await editInvoiceData.mutateAsync(values);
  };

  return (
    <Modal
      isOpen={invoiceEditModal}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            // padding: "50px 32px",
            // height: "500px",
            width: "600px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
    >
      <form
        style={{
          padding: "15px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Typography
            style={{ display: "flex", alignItems: "left", padding: "10px" }}
          >
            Invoice Details Update
          </Typography>
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              // width: "500px",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                }}
              >
                <div style={{ width: "250px" }}>
                  <Controller
                    name={`invoice_number`}
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          requiredAstric={true}
                          //   disabled={isDisable}
                          fullWidth
                          name={field.name}
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "invoice_number",
                          })}
                          placeholder={intl.formatMessage({
                            id: "invoice_number",
                          })}
                          value={field.value}
                          disabled={
                            currentDefaultFormatDetails.accounting_book ===
                            "ROTESSA"
                              ? true
                              : false
                          }
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <Controller
                    name={`client_reference`}
                    control={control}
                    // rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          requiredAstric={true}
                          //   disabled={isDisable}
                          fullWidth
                          name={field.name}
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "client_reference",
                          })}
                          placeholder={intl.formatMessage({
                            id: "client_reference",
                          })}
                          value={field.value}
                          disabled={
                            currentDefaultFormatDetails.accounting_book ===
                            "ROTESSA"
                              ? true
                              : false
                          }
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                }}
              >
                <div style={{ width: "250px" }}>
                  <Controller
                    name="invoice_date"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <DateRangeSelect
                        {...field}
                        name={field.name}
                        size={SIZE.compact}
                        requiredAstric={true}
                        // disabled={isDisabled}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "invoice_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        disabled={
                          currentDefaultFormatDetails.accounting_book ===
                          "ROTESSA"
                            ? true
                            : false
                        }
                        onChange={(e) => field.onChange(e.date)}
                        // minDate={new Date()}
                        range
                      />
                    )}
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <Controller
                    name="invoice_due_date"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <DateRangeSelect
                        {...field}
                        name={field.name}
                        requiredAstric={true}
                        size={SIZE.compact}
                        // disabled={isDisabled}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "invoice_due_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        disabled={
                          currentDefaultFormatDetails.accounting_book ===
                          "ROTESSA"
                            ? true
                            : false
                        }
                        onChange={(e) => field.onChange(e.date)}
                        // minDate={new Date()}
                        range
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                }}
              >
                <div style={{ width: "250px" }}>
                  <Controller
                    name={`invoice_total_amount`}
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          //   disabled={isDisable}
                          requiredAstric={true}
                          type="number"
                          fullWidth
                          name={field.name}
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "invoice_amount",
                          })}
                          placeholder={intl.formatMessage({
                            id: "invoice_amount",
                          })}
                          value={field.value}
                          disabled={
                            currentDefaultFormatDetails.accounting_book ===
                            "ROTESSA"
                              ? true
                              : originalAmountChanged
                              ? originalAmountChanged
                              : false
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            setOriginalAmountChanged(false);
                            setInvoiceAmountChanged(true);
                          }}
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  />
                </div>
                {findBu?.commission_method !== "DC" && (
                  <div style={{ width: "250px" }}>
                    <Controller
                      name="original_amount"
                      control={control}
                      // rules={{ required: "Required" }}
                      render={({ field }) => (
                        <TextBox
                          {...field}
                          //   disabled={isDisable}

                          type="number"
                          fullWidth
                          name={field.name}
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "Original Amount",
                          })}
                          onChange={(e) => {
                            field.onChange(e);
                            setOriginalAmountChanged(true);
                            setInvoiceAmountChanged(false);
                          }}
                          // placeholder={intl.formatMessage({
                          //   id: "Original Amount",
                          // })}
                          value={field.value}
                          disabled={invoiceAmountChanged}
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                )}
              </div>

              {!currentDefaultFormatDetails.is_compute_due_amount && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                  }}
                >
                  <div style={{ width: "250px" }}>
                    <Controller
                      name={`not_paid_amount`}
                      control={control}
                      rules={{ required: "Required" }}
                      shouldUnregister
                      render={({ field }) => (
                        <>
                          <TextBox
                            {...field}
                            //   disabled={isDisable}
                            requiredAstric={true}
                            type="number"
                            fullWidth
                            name={field.name}
                            size={SIZE.compact}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "due_amount",
                            })}
                            // placeholder={intl.formatMessage({
                            //   id: "due_amount",
                            // })}
                            value={field.value}
                            disabled={
                              currentDefaultFormatDetails.accounting_book ===
                              "ROTESSA"
                                ? false
                                : true
                            }
                            onKeyDown={(e) => {
                              if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
              )}

              <div style={{ padding: "0px 10px" }}>
                <Controller
                  name={`is_cancelled`}
                  control={control}
                  render={({ field }) => (
                    <CheckBoxBaseweb
                      {...field}
                      checked={field.value}
                      style={{ alignItems: "center" }}
                    >
                      <Typography style={{ color: "#333860" }}>
                        {/* {i.id} */}
                        {/* <FormattedMessage id={i.id}>{i.id}</FormattedMessage> */}
                        Mark as Cancelled
                      </Typography>
                    </CheckBoxBaseweb>
                  )}
                />
              </div>
              <div style={{ padding: "0px 10px", paddingTop: "15px" }}>
                <Controller
                  control={control}
                  name="comments"
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      name={field.name}
                      label={intl.formatMessage({
                        id: "comments",
                      })}
                      placeholder={intl.formatMessage({
                        id: "comments",
                      })}
                      size={SIZE.mini}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "end", gap: "5px" }}>
              <div>
                <TextButton
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  type="button"
                  onClick={() => {
                    setInvoiceEditModal(false);
                    setInvoiceAmountChanged(false);
                    setOriginalAmountChanged(false);
                    reset();
                    // setInvoiceEditData({});
                  }}
                >
                  Cancel
                </TextButton>
              </div>
              <div>
                <TextButton
                  size={SIZE.compact}
                  kind={KIND.primary}
                  type="submit"
                  disabled={isSubmitting || errors?.invoice_due_date}
                  isLoading={isSubmitting}
                >
                  Save
                </TextButton>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

const InvExpandCard = ({ original }) => {
  const { customInvoice, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  return (
    <div
      style={{
        background: "#ffffff",
        padding: "10px",
        flex: "1",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <InvLevelCustomFieldsTab
        customFields={original}
        fieldLevel={customInvoice}
        width={"100%"}
        wrapWidth={"280px"}
        invId={original._id}
        isSave={true}
      />

      {original?.patient_name && (
        <div
          style={{
            width: "300px",
          }}
        >
          <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
            Patient Name:
          </ParagraphMedium>
          <ParagraphMedium $style={{ color: "#333860" }}>
            {original?.patient_name ? original?.patient_name : "-"}
          </ParagraphMedium>
        </div>
      )}

      {original?.employer && (
        <div
          style={{
            width: "300px",
          }}
        >
          <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
            Employer:
          </ParagraphMedium>
          <ParagraphMedium $style={{ color: "#333860" }}>
            {original?.employer ? original?.employer : "-"}
          </ParagraphMedium>
        </div>
      )}

      {original?.service_location && (
        <div
          style={{
            width: "300px",
          }}
        >
          <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
            Location of Service:
          </ParagraphMedium>
          <ParagraphMedium $style={{ color: "#333860" }}>
            {original?.service_location ? original?.service_location : "-"}
          </ParagraphMedium>
        </div>
      )}
      {original?.insurance && (
        <div
          style={{
            width: "300px",
          }}
        >
          <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
            Insurance Info:
          </ParagraphMedium>
          <ParagraphMedium $style={{ color: "#333860" }}>
            {original?.insurance ? original?.insurance : "-"}
          </ParagraphMedium>
        </div>
      )}
      {original?.invoice_description && (
        <div
          style={{
            width: "300px",
          }}
        >
          <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
            Invoice Description:
          </ParagraphMedium>
          <ParagraphMedium $style={{ color: "#333860" }}>
            {original?.invoice_description
              ? original?.invoice_description
              : "-"}
          </ParagraphMedium>
        </div>
      )}
    </div>
  );
};

const FilterElementComp = ({
  name = "",
  currency = "",
  value = "",
  color = "",
}) => {
  const format = useFormat();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <div
        style={{
          height: "7px",
          width: "7px",
          backgroundColor: color,
          borderRadius: "50px",
        }}
      ></div>
      <Typography type="p" subType="regular" className="text-secondary">
        {name}:{" "}
        <span style={{ fontWeight: "600", color: "#333860" }}>
          {currencyList[currency]}
          {format.currency({
            amount: value,
          })}
        </span>
      </Typography>
    </div>
  );
};

export default function COInvoiceTable() {
  const { currentOrganization, currentDefaultFormatDetails, referenceData } =
    useContext(MaxyfiContext);

  const { handleModal } = useContext(ModalContext);
  const { customerId } = useParams();
  let dispatch = useDispatch();

  const [queryFilter, setQueryFilter] = useState({});
  const debounceFilter = useDebouncedCallback((filter) => {
    setQueryFilter(filter);
  }, 1000);
  const [searchValue, setSearchValue] = useState("");
  const format = useFormat();

  const [invoiceEditModal, setInvoiceEditModal] = useState(false);
  const [invoiceEditData, setInvoiceEditData] = useState({});
  const [dateValue, setDateValue] = React.useState([]);

  const getEnabledDateFields = (organization) => {
    return _.get(organization, "co_inv_grid", []).filter((inv) => inv?.cid);
  };
  const getCustomInvoiceFields = (organization) => {
    const allowedCategories = ["INVOICE"];
    return _.get(organization, "custom_field_list", []).filter((item) =>
      allowedCategories.includes(item.category)
    );
  };
  // Recursive function to flatten the array

  const isEnabledData = getEnabledDateFields(currentDefaultFormatDetails);

  const menuName = useMemo(
    () =>
      isEnabledData.length > 0 ? "invoice_summary" : "customer_overview_v2",
    [isEnabledData?.length]
  );

  const gridStructure = useQuery(
    [`GRID_STRUCTURE_INVOICE`],
    async () =>
      await getGridStructure({
        organization: currentOrganization,
        menu: menuName,
      }),
    {
      cacheTime: 86400000,
      staleTime: 86400000, // Keep data fresh for 24 hours
    }
  );

  const flattenArray = (arr) => {
    return arr.reduce((flat, item) => {
      // If item is an object with a `nested` array, recursively flatten it
      if (item && typeof item === "object" && Array.isArray(item.nested)) {
        return flat.concat(flattenArray(item.nested));
      }
      // Otherwise, include the current item in the flattened array
      return flat.concat(item);
    }, []);
  };

  const flattenArrays = useMemo(() => {
    if (_.get(gridStructure, "data.data.doc.table", []).length) {
      let invoiceNumberCell = _.get(
        gridStructure,
        "data.data.doc.table",
        []
      ).map((item) => {
        return {
          ...item,
          cell:
            item.accessor === "invoice_number" ? "INVOICE_DOWNLOAD" : item.cell,
        };
      });
      return flattenArray(invoiceNumberCell || []);
    }
  }, [gridStructure?.data?.data]);

  console.log(flattenArrays, "flattenArrays");

  const updatedMergedInvoiceGrid = useMemo(() => {
    if (
      Array.isArray(flattenArrays) &&
      flattenArrays.length &&
      isEnabledData.length
    ) {
      const customInvoiceFields = getCustomInvoiceFields(
        currentDefaultFormatDetails
      );

      const nestedDataFields = flattenArrays
        .map((item, flatindex) => {
          const tableItem = isEnabledData.find(
            (tableItem) => tableItem.cid === item.accessor
          );

          if (!tableItem) return null;

          const filterObject = flattenArrays.find(
            (nestedItem) => nestedItem.accessor === tableItem?.cid
          );

          const nestedArray = [];
          if (tableItem?.nes?.cid) {
            const nestedItem = flattenArrays.find(
              (nestedItem) => nestedItem.accessor === tableItem.nes.cid
            );

            if (nestedItem) {
              if (filterObject) {
                const { width, is_hidden, nested, ...filteredObject } =
                  filterObject;
                filteredObject.alignment = "center";
                nestedArray.push(filteredObject);
              }
              nestedArray.push(nestedItem);
            }
          }

          return {
            ...item,
            ...(nestedArray.length ? { nested: nestedArray } : {}),
            literal_id: tableItem?.ltr
              ? `${tableItem.ltr}${
                  tableItem?.nes?.ltr ? ` / ${tableItem.nes.ltr}` : ""
                }`
              : item.literal_id,

            col_id: `col_inv_${flatindex}`,
            order: tableItem?.odr ? tableItem.odr : item.order,
          };
        })
        .filter(Boolean);

      const literalAddedData = isEnabledData.reduce((acc, item) => {
        acc[item.cid] = item?.ltr;
        return acc;
      }, {});

      const customFieldStructure = createCustomFieldStructure(
        customInvoiceFields,
        literalAddedData,
        isEnabledData
      );

      const mergedInvoiceGrid = [...nestedDataFields, ...customFieldStructure];

      const isEnabledDataMap = isEnabledData.reduce((acc, item) => {
        acc[item.cid] = item.odr;
        return acc;
      }, {});

      return mergedInvoiceGrid.map((item) => {
        const newOrder =
          isEnabledDataMap[
            item.accessor.startsWith("custom_field.")
              ? item.accessor.replace("custom_field.", "")
              : item.accessor
          ];
        return newOrder !== undefined ? { ...item, order: newOrder } : item;
      });
    }

    return _.get(gridStructure, "data.data.doc.table", []);
  }, [
    flattenArrays,
    isEnabledData,
    currentDefaultFormatDetails,
    gridStructure?.data?.data,
  ]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  // const cachedInvoicesData = queryClient.getQueryData([
  //   `${CO_INVOICES_}${customerId}`,
  //   { filters: queryFilter, gid: _.get(customerData, "data.doc.gid", "") },
  // ]);

  const invoices = useQuery(
    [
      `${CO_INVOICES_}${customerId}`,
      {
        filters: queryFilter,
        // pgid: _.get(customerData, "data.doc.pgid", ""),
        gid: _.get(customerData, "data.doc.gid", ""),
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters, gid } = queryKey[1];
      filters = {
        ...filters,
        ...(queryFilter?.status?.length > 0 &&
        _.get(customerData, "data.doc.pgid")
          ? { pgid: _.get(customerData, "data.doc.pgid", "") }
          : queryFilter?.status?.length > 0 &&
            !customerData?.data?.doc?.pgid &&
            customerData?.data?.doc?._id
          ? { customer_id: customerData?.data?.doc?._id }
          : gid && gid.length > 0
          ? { gid }
          : {}),
        // ...( : {}),
        // ...appAccessRest,
      };

      return await customerInvoiceOverview({
        organization: currentOrganization,
        customer_id: customerId,
        pageIndex: 0,
        pageSize: 0,
        sortBy: "-_id",
        filters, //TODO
      });
    },
    {
      enabled: gridStructure.isSuccess,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns: updatedMergedInvoiceGrid,
      isExpanded: true,
      isInvExpand: true,
      isExpandedHeader: true,
      isSelection: false,
    });
    return columns;
  }, [updatedMergedInvoiceGrid]);

  console.log(columns, "columns____-");

  const deleteChargesInv = useMutation(
    (data) => deleteCharges({ ...data, organization: currentOrganization }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(`${CO_INVOICES_}${customerId}`);
      },
    }
  );

  const { isGid, isNotGid, data } = useMemo(() => {
    let isGid;
    let isNotGid;
    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      isGid = _.get(invoices, "data.data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
      isNotGid = _.get(invoices, "data.data.docs", []).filter(
        ({ pgid }) => pgid !== _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData?.data?.doc?._id
    ) {
      isGid = _.get(invoices, "data.data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
      isNotGid = _.get(invoices, "data.data.docs", []).filter(
        ({ customer_id }) => customer_id !== _.get(customerData, "data.doc._id")
      );
    }

    const data =
      invoices.data &&
      invoices.data.data &&
      invoices.data.data.docs &&
      Array.isArray(invoices.data.data.docs)
        ? invoices.data.data.docs
        : [];

    return { isGid, isNotGid, data };
  }, [invoices.isLoading, invoices.isFetched, invoices.isRefetching]);

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const renderSubComponent = ({ row }) => {
    return <InvExpandCard {...row} />;
  };

  const updateQueryFilter = (activeFilters) => {
    if (activeFilters.length > 0) {
      setQueryFilter({
        status: [...activeFilters],
      });
    } else {
      setQueryFilter({});
    }
  };

  return (
    <div className="co-invoice-section">
      {/* SECTION HEADER */}
      <div className="co-invoice-section_header">
        <MultiFilters
          filters={[
            {
              label: (
                <FilterElementComp
                  color="#FBBD04"
                  name="Due"
                  value={_.get(
                    customerData,
                    "data.doc.due_inv_amount.value",
                    0
                  )}
                  currency={_.get(
                    customerData,
                    "data.doc.due_inv_amount.currency",
                    ""
                  )}
                />
              ),
              value: "due",
            },
            {
              label: (
                <FilterElementComp
                  color="#FD372A"
                  name="Overdue"
                  value={_.get(
                    customerData,
                    "data.doc.overdue_inv_amount.value",
                    0
                  )}
                  currency={_.get(
                    customerData,
                    "data.doc.overdue_inv_amount.currency",
                    ""
                  )}
                />
              ),
              value: "overdue",
            },
          ]}
          setFilter={updateQueryFilter}
        />
        <div style={{ display: "flex", gap: "10px", height: "25px" }}>
          <TextButton
            kind={KIND.tertiary}
            size="mini"
            onClick={() => dispatch(subscriptionsDrawer(true))}
            startEnhancer={() => <RecurringSvg color={"#516beb"} />}
          >
            <ParagraphSmall $style={{ color: "#516beb", fontWeight: "bold" }}>
              Subscription
            </ParagraphSmall>
          </TextButton>
          <TypesOfInvoice />
          <Input
            value={searchValue}
            onChange={(val) => {
              setSearchValue(val.target.value);
              let value = val.target.value;
              if (value && value !== "") {
                debounceFilter({
                  ...queryFilter,
                  invoice_number: val.target.value,
                });
              } else {
                let updatedFilter = { ...queryFilter };
                delete updatedFilter.invoice_number;
                debounceFilter(updatedFilter);
              }
            }}
            size={SIZE.mini}
            placeholder="Search"
            clearable
            clearOnEscape
            overrides={{
              Root: {
                style: () => ({
                  borderLeftColor: "#ceced9",
                  borderRightColor: "#cecde9",
                  borderTopColor: "#cecde9",
                  borderBottomColor: "#cecde9",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  borderTopWidth: "1px",
                  borderBottomWidth: "1px",
                  borderLeftWidth: "1px",
                  borderRightWidth: "1px",
                  width: "182px",
                  background: "white",
                }),
              },
              InputContainer: {
                style: () => ({
                  background: "white",
                }),
              },
              EndEnhancer: {
                style: ({ $theme }) => ({
                  padding: "0px",
                  background: "white",
                }),
              },
            }}
          />
        </div>
      </div>
      {/* SECTION HEADER */}

      <div className="co-invoice-table_wrapper">
        {/* TABLE HEADER */}
        <div className="co-inv-table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="co-table_header-group">
              {headerGroup.headers.map((header, inx) => (
                <div
                  key={header.id}
                  style={{
                    ...(inx === 0
                      ? { maxWidth: header?.column?.columnDef?.width }
                      : {}),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* TABLE HEADER */}

        {/* TABLE BODY */}
        <div className="co-table_body">
          {/* TABLE ROW */}

          {isGid &&
            Array.isArray(isGid) &&
            isGid.length > 0 &&
            customerData &&
            customerData.data &&
            customerData.data.doc &&
            (customerData.data.doc.pgid || isNotGid.length > 0) && (
              <div className="co_inv_sub_header">
                <Typography type="p">Selected Consumer Debts</Typography>
              </div>
            )}

          {table.getRowModel().rows.map((row, i) => {
            if (
              (row &&
                row.original &&
                !row.original.pgid &&
                _.get(row, "original.customer_id") ===
                  _.get(customerData, "data.doc._id")) ||
              (row &&
                row.original &&
                row.original.pgid &&
                _.get(row, "original.pgid") ===
                  _.get(customerData, "data.doc.pgid"))
            ) {
              return (
                <>
                  <InvoiceBody
                    deleteChargesInv={deleteChargesInv}
                    row={row}
                    renderSubComponent={renderSubComponent}
                    setInvoiceEditData={setInvoiceEditData}
                    setInvoiceEditModal={setInvoiceEditModal}
                  />
                  {row?.original?.invoice_description &&
                    !row?.getIsExpanded() && (
                      <div className="co_table_inv_des">
                        <Typography type="p" subType="small">
                          <span style={{ color: "#ADADAD" }}>
                            Inv-Description:
                          </span>{" "}
                          {row?.original?.invoice_description
                            ? row?.original?.invoice_description
                            : "-"}
                        </Typography>
                      </div>
                    )}
                </>
              );
            }
          })}

          {isNotGid && Array.isArray(isNotGid) && isNotGid.length > 0 && (
            <div className="co_inv_sub_header">
              <Typography type="p">Other Consumer Debts</Typography>
            </div>
          )}
          {table.getRowModel().rows.map((row, i) => {
            if (
              (row &&
                row.original &&
                row.original.pgid &&
                _.get(row, "original.pgid") !==
                  _.get(customerData, "data.doc.pgid")) ||
              (row &&
                row.original &&
                !row.original.pgid &&
                _.get(row, "original.customer_id") !==
                  _.get(customerData, "data.doc._id"))
            ) {
              return (
                <>
                  <InvoiceBody
                    deleteChargesInv={deleteChargesInv}
                    row={row}
                    renderSubComponent={renderSubComponent}
                    setInvoiceEditData={setInvoiceEditData}
                    setInvoiceEditModal={setInvoiceEditModal}
                  />
                  {row?.original?.invoice_description && (
                    <div className="co_table_inv_des">
                      <Typography type="p">
                        <span>Inv-Description:</span>{" "}
                        {row?.original?.invoice_description
                          ? row?.original?.invoice_description
                          : "-"}
                      </Typography>
                    </div>
                  )}
                </>
              );
            }
          })}
          <div style={{ height: "40px" }}></div>
          {/* TABLE ROW */}
        </div>
        {/* TABLE BODY */}
      </div>
      <InvoiceEditModal
        invoiceEditModal={invoiceEditModal}
        setInvoiceEditModal={setInvoiceEditModal}
        invoiceEditData={invoiceEditData}
        setInvoiceEditData={setInvoiceEditData}
      />
    </div>
  );
}
